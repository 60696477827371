import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useHistory } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper';

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

function ProductHeader() {
  let history = useHistory();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [data, setData] = useState([
    {
      name: 'Tendas',
      introduction:
        'No nosso serviço de tendas irá encontrar desde tendas conicas, multistandard, entre outros',
      img: 'https://media.istockphoto.com/id/471435557/photo/a-white-event-tent-in-a-garden.jpg?b=1&s=170667a&w=0&k=20&c=5SbQAOKEQUV2AqgeFVJCCF13kuNuP-Qcupjqo1Vxaoc=',
      type: '/tendas'
    },
    {
      name: 'Palcos',
      introduction:
        'No nosso serviço de Palcos irá encontrar desde Orbital, Movel, Tradicional, Regie, Torres Pa, Grades Antipânico',
      img: 'https://images.unsplash.com/photo-1550697797-f01b4e83a1be?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2076&q=80',
      type: '/palcos'
    },
    {
      name: 'Stands',
      introduction:
        'No nosso serviço de Feiras irá encontrar desde Stands, Casas de Madeira, Bancadas',
      img: 'https://images.unsplash.com/photo-1664033614806-48afc482ef80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2834&q=80',
      type: '/stands'
    },
    {
      name: 'Audiovisual',
      introduction:
        'No nosso serviço Audiovisual irá encontrar desde Venda e aluguer de leds / efeitos especiais',
      img: 'https://images.unsplash.com/photo-1517353334933-3365be5c8ec3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
      type: '/audiovisual'
    },
    {
      name: 'Agenciamento',
      introduction:
        'No nosso serviço de Agenciamento irá encontrar desde Bandas populares, Artistas, Djs',
      img: 'https://images.unsplash.com/photo-1493676304819-0d7a8d026dcf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80',
      type: '/bandas'
    },
    {
      name: 'Equipamentos',
      introduction:
        'No nosso serviço de equipamentos irá encontrar desde geradores, mesas dj, modulos, entre outros',
      img: 'https://images.unsplash.com/photo-1571266028243-d220c6a7edbf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
      type: '/equipamentos'
    },
    // {
    //   name: 'Energy',
    //   introduction: 'No nosso serviço Energy irá encontrar desde Geradores, Torres de Iluminação',
    //   img: 'https://images.unsplash.com/photo-1458007683879-47560d7e33c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2043&q=80',
    //   type: '/graphicart'
    // },
    {
      name: 'Artes Gráficas',
      introduction:
        'No nosso serviço de Artes Gráficas irá encontrar desde Cartazes, Lonas, Vinil, Brindes',
      img: 'https://images.unsplash.com/photo-1621446511304-2ebda9cf70f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
      type: '/graphicart'
    }
    // {
    //   name: 'Modular',
    //   introduction:
    //     "No nosso serviço modular irá encontrar desde venda e aluguer de Módulos, Camarins, Wc's",
    //   img: 'https://images.unsplash.com/photo-1663145034888-a8da016daeda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
    //   type: '/graphicart'
    // }
  ]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="product-header">
      <div className="product-header-title">
        <h1>Os nossos produtos</h1>
      </div>

      {/* <Swiper
        slidesPerView={windowSize.innerWidth > 1000 ? 3 : windowSize.innerWidth < 640 ? 1 : 2}
        spaceBetween={30}
        pagination={{
          clickable: true
        }}
        modules={[Pagination]}
        className="mySwiper"> */}
      <div className="row">
        {data.map((item, i) => {
          return (
            <div className="col-lg-4" key={i}>
              <div className="swipe-content">
                <div className="swipe-content-card">
                  <div className="swipe-content-card-img">
                    <img src={item.img} />
                  </div>
                  <div className="swipe-content-card-main">
                    <h5>{item.name}</h5>
                    <p>{item.introduction}</p>
                    <button
                      onClick={() => (window.location.href = item.type)}
                      className="btn btn-danger">
                      Saber mais
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* </Swiper> */}
    </div>
  );
}

export default ProductHeader;
