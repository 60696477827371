import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function SobreNos() {
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Sobre Nós</h2>
          <div className="content-Sobre">
            <AnimationOnScroll animateIn="animate__bounceIn">
              <p>
                Pro Tendas é uma marca que nasceu em 2009, focada no aluguer de tendas e palcos na
                zona oeste. Ao longo dos anos criou diversos produtos para o servir. A Pro Tendas
                tem uma vasta gama de serviços que lhe permite estar totalmente preparada para todo
                o tipo de eventos e para as suas necessidades, desde montagem de palco, aluguer e
                montagem de tendas, artistas musicais, marketing e publicidade, entre outros.
              </p>
              <h5>O parceiro ideal para o seu evento!</h5>
            </AnimationOnScroll>
          </div>
        </div>

        <DemoFooter />
      </div>
    </>
  );
}

export default SobreNos;
