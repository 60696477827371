/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'assets/scss/paper-kit.scss?v=1.3.0';
import 'assets/demo/demo.css?v=1.3.0';
// pages
import Index from 'views/Index.js';
import LandingPage from 'views/examples/LandingPage.js';
import ProfilePage from 'views/examples/ProfilePage.js';
import RegisterPage from 'views/examples/RegisterPage.js';
import GraphicArt from 'views/examples/graphicArt';
import BandasPage from 'views/examples/bandas';
import Servicos from 'views/examples/servicos';
import PalcosPage from 'views/examples/palcos';
import AudiovisualPage from 'views/examples/audiovisual';
import Stands from 'views/examples/stands';
import EquipamentoPage from 'views/examples/equipamento';
import TendasPage from 'views/examples/tendas';
import SobreNos from 'views/examples/sobreNos';
// others

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={props => <Index {...props} />} />
      <Route path="/stands" render={props => <Stands {...props} />} />
      <Route path="/audiovisual" render={props => <AudiovisualPage {...props} />} />
      <Route path="/palcos" render={props => <PalcosPage {...props} />} />
      <Route path="/landing-page" render={props => <LandingPage {...props} />} />
      <Route path="/produtos" render={props => <Servicos {...props} />} />
      <Route path="/graphicart" render={props => <GraphicArt {...props} />} />
      <Route path="/bandas" render={props => <BandasPage {...props} />} />
      <Route path="/contacts" render={props => <ProfilePage {...props} />} />
      <Route path="/about" render={props => <SobreNos {...props} />} />
      <Route path="/register-page" render={props => <RegisterPage {...props} />} />
      <Route path="/equipamentos" render={props => <EquipamentoPage {...props} />} />
      <Route path="/tendas" render={props => <TendasPage {...props} />} />

      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>
);
