import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import BandasDetail from './bandasDetail';
import PalcoDetail from './palcoDetail';
import ProductDetail from './productDetail';

function PalcosPage() {
  const [data, setData] = useState([
    
    {
      name: 'Palco Tradicional',
      img: 'Paco-de-2-aguas-com-10x6-8x6-8x4.jpeg',
      intro: 'Palco de 2 aguas com 10x6 - 8x6 - 8x4'
    },
    {
      name: 'Palco Tradicional',
      img: 'Palco-com-abas-com-12x10-10x8.jpeg',
      intro: 'Palco com abas com 12x10 - 10x8'
    },
    {
      name: 'Palco Tradicional',
      img: 'Palco-redondo-10x8-8x6.jpeg',
      intro: 'Palco redondo com 10x8 - 8x6'
    },
    {
      name: 'Palco Tradicional',
      img: 'Palco-sem-abas-com12x10-10x8.jpeg',
      intro: 'Palco redondo com 10x8 - 8x6'
    },
    
    {
      name: 'Palco Tradicional',
      img: '10x8-tipo-orbital.jpeg',
      intro: '10x8 tipo orbital'
    },
    {
      name: 'Palco Orbital',
      img: 'palco-orbital.png',
      intro:
        'Altura - Medidas modulares | Largura - 7.5 / 10 / 12.5 / 15 / 20 / 25 / 30 / 35 / 40 Mt | Comprimento - 5 em 5 Mt ilimitado | Revestimento - COBERTURA E LATERAIS gM CRISTAL, TRANSLÚCIDO E OPACO. TELAS COM CLASSIFICAÇÃO DE RESISTÊNCIA AO FOGO M2; M1; MO | Estrutura - PERFIS EM ALUMÍNIO'
    },
    {
      name: 'Palco Tradicional',
      img: '6x4com-pala.jpeg',
      intro: '6x4 com pala'
    },
    {
      name: 'Palco Móvel',
      img: 'palco-movel.png',
      intro:
        'Altura - 4,5 / 5,5 / 6 Mt | Largura - 8,5 / 9 / 10 / 10.5 / 11 / 12 / 13  Mt | Comprimento: 7,5 Mt'
    },
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Palcos</h2>
          <div className="row">
            {data.map((item, i) => {
              return (
                <div className="col-md-4 col-center" key={i}>
                  <div className="col-image-bandas">
                    <PalcoDetail item={item} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default PalcosPage;
