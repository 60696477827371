import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import StandsDetail from './standsDetail';

function Stands() {
  const [estruturasAlum, setEstruturasAlum] = useState([
    {
      name: 'Estruturas em Alumínio para Stands',
      img: 'IMG_20150611_140428.jpg',
      intro:
        'Dispomos de sistemas em alumínio que nos permitem montar os tradicionais stands tipo com diferentes métricas, fornecendo a alcatifa, a estrutura de alumínio com painéis em melamina, a infraestrutura elétrica, a iluminação, a identificação do expositor e o mobiliário necessário. Com estas estruturas podemos conceber e construir as áreas de registo, salas de reunião, auditórios, salas de apoio ou salas para o staff. O material de que dispomos permite-nos montar alguns milhares de m2 de stands. Ainda com recurso aos sistemas em alumínio podemos conceber e montar stands à medida que serão sempre de montagem mais rápida do que os de estruturas em carpintaria, respeitando os objetivos e a linha de comunicação da marca em presença.'
    },
    {
      name: 'Estruturas em Alumínio para Stands',
      img: 'HTB1mHCiLpXXXXXRXVXXq6xXFXXXx.jpg',
      intro:
        'Dispomos de sistemas em alumínio que nos permitem montar os tradicionais stands tipo com diferentes métricas, fornecendo a alcatifa, a estrutura de alumínio com painéis em melamina, a infraestrutura elétrica, a iluminação, a identificação do expositor e o mobiliário necessário. Com estas estruturas podemos conceber e construir as áreas de registo, salas de reunião, auditórios, salas de apoio ou salas para o staff. O material de que dispomos permite-nos montar alguns milhares de m2 de stands. Ainda com recurso aos sistemas em alumínio podemos conceber e montar stands à medida que serão sempre de montagem mais rápida do que os de estruturas em carpintaria, respeitando os objetivos e a linha de comunicação da marca em presença.'
    },
    {
      name: 'Estruturas em Alumínio para Stands',
      img: 'estruturas-aluminio-3.jpg',
      intro:
        'Dispomos de sistemas em alumínio que nos permitem montar os tradicionais stands tipo com diferentes métricas, fornecendo a alcatifa, a estrutura de alumínio com painéis em melamina, a infraestrutura elétrica, a iluminação, a identificação do expositor e o mobiliário necessário. Com estas estruturas podemos conceber e construir as áreas de registo, salas de reunião, auditórios, salas de apoio ou salas para o staff. O material de que dispomos permite-nos montar alguns milhares de m2 de stands. Ainda com recurso aos sistemas em alumínio podemos conceber e montar stands à medida que serão sempre de montagem mais rápida do que os de estruturas em carpintaria, respeitando os objetivos e a linha de comunicação da marca em presença.'
    },
    {
      name: 'Estruturas em Alumínio para Stands',
      img: 'STAND3X3+ESTRADO_01(2).JPG',
      intro:
        'Dispomos de sistemas em alumínio que nos permitem montar os tradicionais stands tipo com diferentes métricas, fornecendo a alcatifa, a estrutura de alumínio com painéis em melamina, a infraestrutura elétrica, a iluminação, a identificação do expositor e o mobiliário necessário. Com estas estruturas podemos conceber e construir as áreas de registo, salas de reunião, auditórios, salas de apoio ou salas para o staff. O material de que dispomos permite-nos montar alguns milhares de m2 de stands. Ainda com recurso aos sistemas em alumínio podemos conceber e montar stands à medida que serão sempre de montagem mais rápida do que os de estruturas em carpintaria, respeitando os objetivos e a linha de comunicação da marca em presença.'
    }
  ]);

  const [modulares, setModulares] = useState([
    {
      name: 'Desenvolvimento e produção de stands personalizados ou modulares',
      img: '309835497_609339247558022_1378390855814962303_n.jpg',
      intro:
        'Dependendo dos objetivos que a sua empresa ou instituição definir para a Vossa participação numa Feira, num Congresso ou num evento, em território nacional ou no estrangeiro, a nossa equipa de design definirá e proporá o conceito para o stand respeitando a linha de comunicação que for definida. Estamos sempre disponíveis para trabalhar com os nossos clientes sobre a proposta apresentada e incorporar eventuais alterações que a tornem mais ajustada aos Vossos objetivos.'
    },
    {
      name: 'Desenvolvimento e produção de stands personalizados ou modulares',
      img: 'inapa-kislog-stands-para-feiras-e-eventos.jpg',
      intro:
        'Dependendo dos objetivos que a sua empresa ou instituição definir para a Vossa participação numa Feira, num Congresso ou num evento, em território nacional ou no estrangeiro, a nossa equipa de design definirá e proporá o conceito para o stand respeitando a linha de comunicação que for definida. Estamos sempre disponíveis para trabalhar com os nossos clientes sobre a proposta apresentada e incorporar eventuais alterações que a tornem mais ajustada aos Vossos objetivos.'
    }
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Stands</h2>
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>
                <h4>Estruturas Aluminio</h4>
              </Tab>
              <Tab>
                <h4>Personalizados/Modulares</h4>
              </Tab>
            </TabList>

            <TabPanel>
              <div className="row">
                {estruturasAlum.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        <StandsDetail type="stands" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {modulares.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        <StandsDetail type="stands" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default Stands;
