import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import BandasDetail from './bandasDetail';
import ProductDetail from './productDetail';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import AudioVisualDetail from './audioVisualDetail';

function AudiovisualPage() {
  const [efeitosEspeciais, setEfeitosEspeciais] = useState([
    {
      name: 'CO2 JET',
      img: 'equipco2.png',
      intro:
        'É um dispositivo de efeito para a criação de efeitos de neblina de CO2.A activação do Jet é feita exclusivamente pelo uso da alimentação com tensão de rede de 230V.'
    },
    {
      name: 'FLAME JET',
      img: 'flameJet.png',
      intro: 'Dispositivo de efeito de chama controlado, produz colunas de chama'
    },
    {
      name: 'MÁQUINA DE FOGO S-500',
      img: 'equipamentoNeve.png',
      intro:
        'Seu corpo principal e tanque de fluido de 20 litros são colocados dentro de uma case com rodas. Equipamento preparado para o seu uso durante longas horas.'
    },
    {
      name: 'CO2 LED',
      img: 'co2led.png',
      intro: 'Produz até 10 metros de comprimento, rajadas de neblina iluminadas, ângulo ajustável'
    },
    {
      name: 'CO2 GUN',
      img: 'co2gun.png',
      intro:
        'É um dispositivo de efeito prático para a criação de efeitos de neblina de CO2 controlados. Este produto apresenta baixo peso e a pega e o gatilho integrados do dispositivo proporcionam um manuseio intuitivo.'
    },

    {
      name: 'FLAME WAVE',
      img: 'flameWave.png',
      intro:
        'O X2-Wave-Flamer combina um sistema de chama acionada por fluido, com tecnologia de cabeça móvel, em um dispositivo compacto. Equipado com uma cabeça motorizada e giratória, o X2-Wave-Flamer permite rajadas de chama muito rápidas e precisas de até 10 metros de altura.'
    },
    {
      name: 'HEAD FIRE MACHINE',
      img: 'headFire.png',
      intro: 'É um equipamento que atira chama em 3 direcções diferente.'
    },
    {
      name: 'CO2 TURBO JET',
      img: 'co2turbojet.png',
      intro:
        'Efeitos incríveis com jactos de até 8 metros de altura. Uma explosão de efeitos visuais e sonoros'
    }
  ]);

  const [ecrasLeds, setEcrasLeds] = useState([
    {
      name: 'Lojas e Montras',
      img: '5a3c7aed-4ba1-4a74-8ab8-8dca5ab89000.jpeg',
      intro:
        "Equipe a montra e o interior da sua loja com ecrãs LED dinâmicos e de alta resolução. Dispomos de soluções fixas a integrar na arquitetura e decoração da loja e soluções amovíveis de fácil mobilidade, como os LED Poster's e ecrãs modulares."
    },
    {
      name: 'Reclamos Digitais',
      img: 'lojasMontras.png',
      intro:
        'Substitua os reclamos estáticos (sem vida) por uma Solução LED Digital e diferencie-se da concorrência! Os nossos Reclamos Digitais são perfeitos para o seu estabelecimento anunciar campanhas, promoções e vídeos dinâmicos junto do público. Poderá mudar os conteúdos sempre que quiser!'
    },
    {
      name: 'Painéis Billboard',
      img: 'painesBillboard.png',
      intro:
        'Preparados para ambientes exteriores, os Ecrãs LED Outdoor proporcionam alto brilho e resolução, assegurando uma excelente visibilidade a longas distâncias.'
    }
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Audiovisual</h2>
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>
                <h4>Ecrãs Leds</h4>
              </Tab>
              <Tab>
                <h4>Efeitos Especiais</h4>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="titleLeds">
                <h4>Deseja alugar Ecrãs Gigantes para o seu evento?</h4>
                <h5>
                  Alugamos Ecrãs LED Wall de alta resolução para espetáculos, conferências,
                  comícios, eventos corporativos, desportivos, entre outros.
                </h5>
              </div>
              <div className="row">
                {ecrasLeds.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        <AudioVisualDetail item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {efeitosEspeciais.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        <AudioVisualDetail item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default AudiovisualPage;
