import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import BandasDetail from './bandasDetail';
import ProductDetail from './productDetail';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PalcoDetail from './palcoDetail';

function EquipamentoPage() {
  const [efeitosEspeciais, setEfeitosEspeciais] = useState([
    {
      name: 'ALCATIFA COM PLÁSTICO DE PROTECÇÃO',
      img: 'alcatifa.png',
      intro:
        'Composição: 100% Polipropileno | Altura total: 2,6mm | Larguras do rolo: 2,00 - 3,00 (dependendo da cor) | Comprimento: 30,00mts | M3'
    },
    {
      name: 'Aquecedor',
      img: 'aquecedore-500x500.jpg',
      intro: 'Aquecedor Exterior'
    },
    {
      name: 'Pista de dança',
      img: 'Pista-de-Dança-1-500x480.jpg',
      intro: 'Pista de dança'
    },
    {
      name: 'Barreira Antipânico',
      img: 'panico-barreiras.jpg',
      intro: 'Barreiras em alumínio com acabamento especial e polimento. Fácil e rápido de montar.'
    },
    
    {
      name: 'Bancada',
      img: 'Bancada.jpeg',
      intro: 'Bancada completa para público.'
    },
    
    
  ]);

  const [mobiliario, setMobiliario] = useState([
 
    {
      name: 'Banquinhos',
      img: 'Banquinhos-500x500.jpg',
      intro: 'Bancos'
    },
   
    {
      name: 'Cadeira Cristal',
      img: 'cadeiras-cristal.jpg',
      intro: 'Cadeiras cristal'
    },
    {
      name: 'Cadeiras',
      img: 'cadeira-preta.jpg',
      intro: 'Cadeiras simples'
    },
    {
      name: 'Conjunto',
      img: 'Mesaxcadeiras-brancas.JPG',
      intro: 'Conjunto de cadeiras e mesa branca.'
    },
   
    
  ]);

  const [truss, setTruss] = useState([
    {
      name: 'Truss',
      img: 'png-clipart-stage-set-up-light-truss-banquet-lighting-truss-banquet-love-frame-thumbnail.png',
      intro: 'Truss'
    },

    {
      name: 'Truss',
      img: 'imagestrusss124.png',
      intro:
        'Temos ao vosso dispor truss triangular ou truss quadrado de varias série de tamanho para a exposição, decoração, casamento, publicidade, Concerto, o desempenho do interior ou exterior, parque de exposições, car show, casamentos, eventos, festas, discotecas, categoria:  100x100mm;120x120mm;220x220mmPara pequenas festas, concertos, parte:  290x290mm;390x390mm;400x400mmPara as médias dos festivais, concertos, partes:  530*530mm;470*610mmPara grandes festas, concertos, partes:  520*760mm;580*1010mmAcima é o tamanho comum.'
    },
    {
      name: 'Truss',
      img: 'kisspng-truss-king-post-lighting-aluminium-disc-jockey-stage-light-5ac364170da654.6160062315227545830559.png',
      intro: 'Truss'
    },
    {
      name: 'Truss',
      img: 'kisspng-stage-lighting-nysesq-truss-structure-aluminum-triangle-truss-pictures-to-pin-on-pintere-5bae392f81df04.110189251538144559532.png',
      intro:
        'Temos ao vosso dispor truss triangular ou truss quadrado de varias série de tamanho para a exposição, decoração, casamento, publicidade, Concerto, o desempenho do interior ou exterior, parque de exposições, car show, casamentos, eventos, festas, discotecas, categoria:  100x100mm;120x120mm;220x220mmPara pequenas festas, concertos, parte:  290x290mm;390x390mm;400x400mmPara as médias dos festivais, concertos, partes:  530*530mm;470*610mmPara grandes festas, concertos, partes:  520*760mm;580*1010mmAcima é o tamanho comum.'
    },
    {
      name: 'Truss',
      img: 'lrgrec_6.jpg',
      intro:
        'Temos ao vosso dispor truss triangular ou truss quadrado de varias série de tamanho para a exposição, decoração, casamento, publicidade, Concerto, o desempenho do interior ou exterior, parque de exposições, car show, casamentos, eventos, festas, discotecas, categoria:  100x100mm;120x120mm;220x220mmPara pequenas festas, concertos, parte:  290x290mm;390x390mm;400x400mmPara as médias dos festivais, concertos, partes:  530*530mm;470*610mmPara grandes festas, concertos, partes:  520*760mm;580*1010mmAcima é o tamanho comum.'
    }
  ]);

  const [geradores, setGeradores] = useState([
    {
      name: 'Gerador',
      img: 'gerador1.png',
      intro: 'Dispomos de geradores desde os 30 aos 500KVA'
    },
    {
      name: 'Gerador',
      img: 'gerador2.png',
      intro: 'Dispomos de geradores desde os 30 aos 500KVA'
    }
  ]);

  const [modulos, setModulos] = useState([
    {
      name: 'Módulo | Projectamos, fabricamos e instalamos soluções à medida das suas necessidades e requisitos',
      img: 'modular.png',
      intro:
        'Temporário | Permanente | Unifamiliares | Plurifamiliares | Stands de Vendas | Bares | Sanitários | Balneários | Bilheteiras | Camarins | Portaria | Lojas'
    },

    {
      name: 'Módulo | Projectamos, fabricamos e instalamos soluções à medida das suas necessidades e requisitos',
      img: 'modular1.png',
      intro:
        'Temporário | Permanente | Unifamiliares | Plurifamiliares | Stands de Vendas | Bares | Sanitários | Balneários | Bilheteiras | Camarins | Portaria | Lojas'
    }
  ]);
  const [wcs, setWcs] = useState([
    {
      name: 'Wc químicos portáteis',
      img: 'wc2.png',
      intro: 'Temos WC Simples, WC VIP, Urinóis quádruplos, WC Especial para deficientes'
    },
    {
      name: 'Wc químicos portáteis',
      img: 'wc1.png',
      intro: 'Temos WC Simples, WC VIP, Urinóis quádruplos, WC Especial para deficientes'
    },
    {
      name: 'Wc químicos portáteis',
      img: 'wc3.png',
      intro: 'Temos WC Simples, WC VIP, Urinóis quádruplos, WC Especial para deficientes'
    },
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Equipamentos</h2>

          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>
                <h4>Geral</h4>
              </Tab>
              <Tab>
                <h4>MOBILIÁRIO</h4>
              </Tab>
              <Tab>
                <h4>Truss</h4>
              </Tab>
              <Tab>
                <h4>Geradores</h4>
              </Tab>
              <Tab>
                <h4>Modulos</h4>
              </Tab>
              <Tab>
                <h4>Wc's</h4>
              </Tab>
            </TabList>
            <TabPanel>
              <div className="row">
                {efeitosEspeciais.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {mobiliario.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {truss.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {geradores.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {modulos.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="row">
                {wcs.map((item, i) => {
                  return (
                    <div className="col-md-4 col-center" key={i}>
                      <div className="col-image-bandas">
                        {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                        <PalcoDetail type="equipamento" item={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default EquipamentoPage;
