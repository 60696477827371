/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components

// core components

function ProfilePageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = 'translate3d(0,' + windowScrollTop + 'px,0)';
      };
      window.addEventListener('scroll', updateScroll);
      return function cleanup() {
        window.removeEventListener('scroll', updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={
          {
            // backgroundImage:
            //   "url(" + require('assets/video/backVideo.mp4') + ")"
          }
        }
        className="page-header page-header-xs"
        data-parallax={true}
        ref={pageHeader}>
        <video loop autoPlay muted playsInline className="back-video">
          <source src={require('assets/video/backVideo.mp4')} type="video/mp4"></source>
        </video>
        <div className="filter" />
      </div>
    </>
  );
}

export default ProfilePageHeader;
