import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ContactForm from 'views/contactForm/contactForm';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  overlay: {
    zIndex: 100000,
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(8px)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'auto',
    maxWidth: ' 35%',
    height: 'auto',
    // minHeight: '70vh',
    maxHeight: '90vh',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  }
};

Modal.setAppElement('#root');

export default function PalcoDetail({ item, type }) {
  const [modalIsOpen, setOpen] = React.useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <div>
      {type == 'equipamento' ? (
        <img onClick={() => openModal()} src={require('assets/img/equipamentos/' + item.img)} />
      ) : (
        <img onClick={() => openModal()} src={require('assets/img/palcos/' + item.img)} />
      )}

      <Modal
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="product-detail-modal">
          <div className="row">
            <div className="col-10">
              <h4 className="title-banda-detail">{item.name}</h4>
            </div>
            <div className="col-2">
              <a className="close-modal" onClick={closeModal}>
                <AiOutlineClose />
              </a>
            </div>
          </div>
          <div className="row " style={{ marginBottom: '30px !important' }}>
            <div className="col-md-12">
              <div className="">
                <p>{item.intro}</p>
              </div>
            </div>
            <div className="col-md-12 col-bandas">
              {type == 'equipamento' ? (
                <img src={require('assets/img/equipamentos/' + item.img)} />
              ) : (
                <img src={require('assets/img/palcos/' + item.img)} />
              )}
            </div>
          </div>
          <div className="bottom-buttons">
            <div className="row">
              <div className="col-md-12">
                {/* <ContactForm /> */}
                <a href="/contacts" className="btn btn-danger" type="submit">
                  Contacta-nos
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
