import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ContactForm from 'views/contactForm/contactForm';
import { AiOutlineClose } from 'react-icons/ai';

const customStyles = {
  overlay: {
    zIndex: 100000,
    position: 'fixed',
    background: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(8px)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '30%',
    height: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'visible'
  }
};

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 37.42216,
  lng: -122.08427,
}

Modal.setAppElement('#root');

export default function ProductDetail({ Info, type }) {
  const [modalIsOpen, setOpen] = React.useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <div>
      <img onClick={openModal} src={require('assets/img/graficart/' + Info.img + '.jpg')} />
      <Modal
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="product-detail-modal">
          <div className="row">
            <div className="col-10">
              <h4 className="title-banda-detail">{Info.name}</h4>
            </div>
            <div className="col-2">
              <a className="close-modal" onClick={closeModal}>
                <AiOutlineClose />
              </a>
            </div>
          </div>
          <div className="row " style={{ marginBottom: '30px !important' }}>
            <div className="col-md-12 col-bandas">
              <img
              style={{ maxHeight: '90vh' }}
                src={
                  Info.link
                    ? require('assets/img/bandas/' + Info.img)
                    : require('assets/img/graficart/' + Info.img + '.jpg')
                }
              />
            </div>
     
          </div>
          <div className="bottom-buttons">
            <div className="row">
              <div className="col-md-12">
                {/* <ContactForm /> */}
                <a href="/contacts" className="btn btn-danger" type="submit">
                  Contacta-nos
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
