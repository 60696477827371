import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ContactForm from 'views/contactForm/contactForm';
import { AiOutlineClose } from 'react-icons/ai';



Modal.setAppElement('#root');

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


export default function BandasDetail({ type, item }) {
  const [modalIsOpen, setOpen] = React.useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const customStyles = {
    overlay: {
      zIndex: 100000,
      position: 'fixed',
      background: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(8px)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: 'auto',
      maxWidth: windowDimensions.width > 1000 ? '35%' : '35%',
      height: windowDimensions.width > 1000 ? '90%' : 'auto',
      transform: 'translate(-50%, -50%)',
      overflow: 'visible'
    }
  };

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <div>
      {type == 'artistas' ? (
        <img onClick={() => openModal()} src={require('assets/img/artistas/' + item.img)} />
      ) : (
        <img onClick={() => openModal()} src={require('assets/img/bandas/' + item.img)} />
      )}
      <Modal
        onRequestClose={closeModal}
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="product-detail-modal mt-2">
          <div className="row">
            <div className="col-10">
              <h4 className="title-banda-detail">{item.name}</h4>
            </div>
            <div className="col-2">
              <a className="close-modal" onClick={closeModal}>
                <AiOutlineClose />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-bandas">
              {type == 'artistas' ? (
                <img
                  src={
                    item.link
                      ? require('assets/img/artistas/' + item.img)
                      : require('assets/img/graficart/' + item.img + '.jpg')
                  }
                />
              ) : (
                <img
                  src={
                    item.link
                      ? require('assets/img/bandas/' + item.img)
                      : require('assets/img/graficart/' + item.img + '.jpg')
                  }
                />
              )}
            </div>
            <div className="col-md-12">
              <div className="scroll-div">
                <p>{item.intro}</p>
              </div>
            </div>
          </div>
          <div className="bottom-buttons">
            <div className="row">
              <div className="col-md-6">
                {item.link !== '' && (
                  <a target="_blank" className="btn btn-danger mr-3" href={item.link}>
                    Youtube
                  </a>
                )}
              </div>
              <div className="col-md-6">
                {/* <ContactForm /> */}
                <a href="/contacts" className="btn btn-danger" type="submit">
                  Contacta-nos
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
