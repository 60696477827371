import './contactForm.scss';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = ({ noTitle }) => {
  const form = useRef();
  const [sucessMessage, setSucessMessage] = useState(false);

  const sendEmail = e => {
    e.preventDefault();

    emailjs.sendForm('service_943zt3r', 'template_3ujddxn', form.current, 'mKCu5OOoAq-qtlADw').then(
      result => {
        console.log(result.text);
        setSucessMessage(true);
        setTimeout(() => {
          setSucessMessage(false);
        }, 5000);
      },
      error => {
        console.log(error.text);
      }
    );
  };

  return (
    <div className="container mt-5 contact-form">
      {noTitle ? (
        <></>
      ) : (
        <>
          <h5>Contacta-nos</h5>
          <h6>Telm: 917 140 018</h6>
          <h6>Email: protendasobidos@gmail.com</h6>
          <p>
            Para qualquer duvida basta contactar via whatsApp, telemóvel ou envia-nos uma mensagem
            preenchendo este formulário. <br></br>Iremos tentar dar resposta o mais breve possível.
          </p>
        </>
      )}

      {sucessMessage ? (
        <p style={{marginTop: '100px', fontSize: '30px'}}>Obrigado por nos contactares, iremos responder com a maior brevidade.</p>
      ) : (
        <form ref={form} onSubmit={sendEmail}>
          <div>
            <label className="form-label" htmlFor="name">
              Nome
            </label>
            <input className="form-control" type="text" id="name" required />
          </div>
          <div>
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input className="form-control" type="email" id="email" required />
          </div>
          <div>
            <label className="form-label" htmlFor="message">
              Mensagem
            </label>
            <textarea className="form-control" id="message" required />
          </div>
          <button className="btn btn-danger" type="submit">
            Enviar
          </button>
        </form>
      )}
    </div>
  );
};
export default ContactForm;
