import IndexNavbar from 'components/Navbars/IndexNavbar';
import React from 'react';
import ProductHeader from '../../components/Headers/productHeader';

function Servicos() {
  return (
    <div>
      <IndexNavbar />
      <div className="section product-content">
        <ProductHeader />
      </div>
    </div>
  );
}

export default Servicos;
