/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';
import './headers.scss';
// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={
          {
            // backgroundImage: 'url(' + require('assets/img/antoine-barres.jpg') + ')'
          }
        }>
        <video loop autoPlay muted playsInline className="back-video">
          <source src={require('assets/video/backVideo.mp4')} type="video/mp4"></source>
        </video>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <img src={require('assets/img/logo-white-big.png')} />
            </div>
            <div className="sub-title-brand">
              <h1 className="presentation-subtitle text-center">Produções | Realizamos o seu evento</h1>
            </div>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: 'url(' + require('assets/img/clouds.png') + ')'
          }}
        />
        {/* <h6 className="category category-absolute">
          Designed and created by{' '}
          <a href="https://www.facebook.com/fvwebdesign" target="_blank">
            <img
              alt="..."
              className="creative-tim-logo"
              src={require('assets/img/creative-tim-white-slim2.png')}
            />
          </a>
        </h6> */}
      </div>
    </>
  );
}

export default IndexHeader;
