import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import BandasDetail from './bandasDetail';
import ProductDetail from './productDetail';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PalcoDetail from './palcoDetail';

function TendasPage() {
  const [tendas, setTendas] = useState([
    {
      name: 'Tendas Multistandard',
      img: 'multistandard.png',
      intro: 'ALTURA: MEDIDAS MODULARES | Largura: 7.5 / 10 / 12.5 / 15 / 20 / 25 / 30 / 35 / 40 Mt | Comprimento: 5 em 5 Mt ilimitado | REVESTIMENTO: COBERTURA E LATERAIS gM CRISTAL, TRANSLÚCIDO E OPACO. TELAS COM CLASSIFICAÇÃO DE RESISTÊNCIA AO FOGO M2;: M1; MO | TELAS COM CLASSIFICAÇÃO DE RESISTÊNCIA AO FOGO M2;: M1; MO | ESTRUTURA: PERFIS EM ALUMÍNIO'
    },
    {
      name: 'Tendas Iglo',
      img: 'iglo.png',
      intro: 'ALTURA: MEDIDAS MODULARES | Largura: 7.5 / 10 / 12.5 / 15 / 20 / 25 / 30 / 35 / 40 Mt | Comprimento: 5 em 5 Mt ilimitado | REVESTIMENTO: COBERTURA E LATERAIS gM CRISTAL, TRANSLÚCIDO E OPACO. TELAS COM CLASSIFICAÇÃO DE RESISTÊNCIA AO FOGO M2;: M1; MO | ESTRUTURA: PERFIS EM ALUMÍNIO'
    },
    {
      name: 'Tendas Cónicas',
      img: 'conica.png',
      intro: 'ALTURA: MEDIDAS MODULARES | Largura: 5Mt | Comprimento: 5Mt | REVESTIMENTO: COBERTURA OPAC | TELAS COM CLASSIFICAÇÃO DE RESISTÊNCIA AO FOGO M2;: M1; MO | ESTRUTURA: PERFIS EM ALUMÍNIO'
    },
    {
      name: 'Tencionados',
      img: 'tencionados.png',
      intro: ''
    },
    {
      name: 'Projeto 3D',
      img: 'proj1.png',
      intro: ''
    },
    {
      name: 'Projeto 3D',
      img: 'proj2.png',
      intro: ''
    }
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Tendas</h2>

          <div className="row">
            {tendas.map((item, i) => {
              return (
                <div className="col-md-4 col-center" key={i}>
                  <div className="col-image-bandas">
                    {/* <img src={require('assets/img/equipamentos/' + item.img)} /> */}
                    <PalcoDetail item={item} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default TendasPage;
