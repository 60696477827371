/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates strings
import classnames from 'classnames';
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from 'reactstrap';

function IndexNavbar() {
  // const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  // React.useEffect(() => {
  //   const updateNavbarColor = () => {
  //     if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
  //       setNavbarColor('');
  //     } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
  //       setNavbarColor('navbar-transparent');
  //     }
  //   };

  //   window.addEventListener('scroll', updateNavbarColor);

  //   return function cleanup() {
  //     window.removeEventListener('scroll', updateNavbarColor);
  //   };
  // });
  return (
    <Navbar className={'fixed-top'} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand className="logo-navbar" data-placement="bottom" href="/index">
            <img src={require('assets/img/logoReload.png')} />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames('navbar-toggler navbar-toggler', {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}>
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            <NavItem>
              <Button className="btn-round" href="/">
                Home
              </Button>
              <Button className="btn-round" href="/about">
                Sobre Nós
              </Button>
              <Button className="btn-round" href="/produtos">
                Produtos
              </Button>
              <Button className="btn-round" href="/contacts">
                Contactos
              </Button>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.facebook.com/protendasobidos"
                target="_blank"
                title="Like us on Facebook">
                <i className="fa fa-facebook-square" />
                <p className="d-lg-none">Facebook</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.instagram.com/protendas/"
                target="_blank"
                title="Follow us on Instagram">
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            {navbarCollapse &&
            <NavItem className='bottomContactsMenu'>
              <h6>Contacta-nos:</h6>
              <p>917 140 018</p>
              <p>protendasobidos@gmail.com</p>
            </NavItem>
            }
            
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
