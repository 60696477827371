import DemoFooter from 'components/Footers/DemoFooter';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import React, { useState } from 'react';
import ProductDetail from './productDetail';

function GraphicArt() {
  const [data, setData] = useState([
    { img: '9' },
    {
      img: '1'
    },
    {
      img: '2'
    },
    {
      img: '3'
    },
    {
      img: '4'
    },
    {
      img: '5'
    },
    {
      img: '6'
    },
    {
      img: '7'
    },
    {
      img: '8'
    }
  ]);

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="main">
        <div className="product-detail">
          <h2>Artes Graficas</h2>
          <div className="row">
            {data.map((item, i) => {
              return (
                <div className="col-md-4">
                  <div key={i} className="swipe-content col-product">
                    <div className="swipe-content-card">
                      <div className="swipe-content-card-img">
                        <ProductDetail Info={item} />
                      </div>
                      {/* <div className="swipe-content-card-main">
                        
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <DemoFooter />
    </>
  );
}

export default GraphicArt;
