/*!

=========================================================
* Paper Kit React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap components
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {
  Button,
  Label,
  FormGroup,
  Input,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from 'reactstrap';
// core components
import ExamplesNavbar from 'components/Navbars/ExamplesNavbar.js';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.js';
import DemoFooter from 'components/Footers/DemoFooter.js';
import IndexNavbar from '../../components/Navbars/IndexNavbar';
import ContactForm from '../contactForm/contactForm';

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 39.3604,
  lng: 9.1582
};

function ProfilePage() {
  const [activeTab, setActiveTab] = React.useState('1');

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const position = [39.36055, -9.1567];

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="section product-detail profile-content">
      <h2>917 140 018  | protendasobidos@gmail.com</h2>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <ContactForm />
            </Col>
            <Col className="ml-auto mr-auto text-center" md="6">
              <div className="map-wrap">
                <MapContainer
                  style={{ zIndex: '100000' }}
                  
                  center={position}
                  zoom={13}
                  scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position}>
                    <Popup>
                      {/* A pretty CSS3 popup. <br /> Easily customizable. */}
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <DemoFooter />
    </>
  );
}

export default ProfilePage;
